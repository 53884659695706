<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">发证机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="baseform" label-width="10rem" label-position="right" class="form" :rules="baseformRules"
              :model="baseform">
              <el-form-item label="发证机构名称:" prop="authorityName">
                <el-input v-model="baseform.authorityName" size="small" placeholder="请输入发证机构名称" :disabled="!baseform.canDeletedInfo"></el-input>
              </el-form-item>
              <el-form-item label="发证机构名称(英文):" prop="authorityNameEn" class="labelRowTwo">
                <el-input v-model="baseform.authorityNameEn" size="small" placeholder="请输入发证机构名称(英文)" :disabled="!baseform.canDeletedInfo"></el-input>
              </el-form-item>
              <el-form-item label="联系人:" prop="concatMan">
                <el-input v-model="baseform.concatMan" size="small" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系人电话:" prop="concatMobile">
                <el-input v-model="baseform.concatMobile" size="small" placeholder="请输入联系人电话"></el-input>
              </el-form-item>
              <el-form-item label="证书规则:" prop="certType">
                <el-tabs v-model="baseform.certType" @tab-click="tabsChange">
                  <el-tab-pane label="常用规则" name="10">
                    <el-button type="primary" size="small" class="bgc-bv" round @click="tableData_10_ladingDataOpen()"
                      icon="el-icon-plus">新增编号规则</el-button>
                    <el-table ref="multipleTable" :data="tableData_10" :height="tableHeight" size="small"
                      tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" :key="'ssss'">
                      <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod"
                        width="6.25rem" />
                      <el-table-column label="编号规则名称" align="left" show-overflow-tooltip prop="certRoleName"
                        min-width="100" />
                      <el-table-column label="备案号" align="left" show-overflow-tooltip prop="recordNum" min-width="150" />
                      <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaName" min-width="120" />
                      <el-table-column label="机构ID" align="left" show-overflow-tooltip prop="departId" min-width="100" />
                      <el-table-column label="证书号段起" align="left" show-overflow-tooltip prop="codeStart"
                        min-width="150" />
                      <el-table-column label="证书号段止" align="left" show-overflow-tooltip prop="codeEnd" min-width="120" />
                      <el-table-column label="当前证书编号" align="left" show-overflow-tooltip prop="currentCertificateNo"
                        min-width="100" />
                      <el-table-column label="状态" align="left" show-overflow-tooltip prop="certRoleUsable"
                        min-width="150">
                        <template slot-scope="scope">
                          禁用<el-switch v-model="scope.row.certRoleUsable" active-color="#13ce66" inactive-color="#ff4949">
                          </el-switch>启用
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" align="left" show-overflow-tooltip prop="mobile" width="120px">
                        <template slot-scope="scope">
                          <el-button type="text" style="padding:0rem .3125rem" size="mini"
                            @click="tableData_10_ladingDataOpen(scope.row, scope.$index)"
                            :disabled="typeof scope.row.canAllModify == 'boolean' && !scope.row.canAllModify">编辑</el-button>
                          <el-button type="text" style="padding:0rem .3125rem" size="mini"
                            @click="tableData_10_delete(scope.row, scope.$index)"
                            :disabled="typeof scope.row.canDeleted == 'boolean' && !scope.row.canDeleted">删除</el-button>
                        </template>
                      </el-table-column>
                      <Empty slot="empty" />
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="自定义规则" name="20">
                    <el-button type="primary" size="small" class="bgc-bv" round @click="tableData_20_ladingDataOpen()"
                      icon="el-icon-plus">新增编号规则</el-button>
                    <el-table ref="multipleTable" :data="tableData_20" :height="tableHeight" size="small"
                      tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
                      <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod"
                        width="6.25rem" />
                      <el-table-column label="编号规则名称" align="left" show-overflow-tooltip prop="certRoleName"
                        min-width="100" />
                      <el-table-column label="编号前缀" align="left" show-overflow-tooltip prop="certNoPrefix"
                        min-width="150" />
                      <el-table-column label="流水号位数" align="left" show-overflow-tooltip prop="certNoBit"
                        min-width="120" />
                      <el-table-column label="编号起始号码" align="left" show-overflow-tooltip prop="certNoStart"
                        min-width="100" />
                      <el-table-column label="当前证书编号" align="left" show-overflow-tooltip prop="currentCertificateNo"
                        min-width="150" />
                      <el-table-column label="状态" align="left" show-overflow-tooltip prop="certRoleUsable"
                        min-width="150">
                        <template slot-scope="scope">
                          禁用<el-switch v-model="scope.row.certRoleUsable" active-color="#13ce66" inactive-color="#ff4949">
                          </el-switch>启用
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" align="left" show-overflow-tooltip prop="mobile" width="120px">
                        <template slot-scope="scope">
                          <el-button type="text" style="padding:0rem .3125rem" size="mini"
                            @click="tableData_20_ladingDataOpen(scope.row, scope.$index)"
                            :disabled="typeof scope.row.canAllModify == 'boolean' && !scope.row.canAllModify">编辑</el-button>
                          <el-button type="text" style="padding:0rem .3125rem" size="mini"
                            @click="tableData_20_delete(scope.row, scope.$index)"
                            :disabled="typeof scope.row.canDeleted == 'boolean' && !scope.row.canDeleted">删除</el-button>
                        </template>
                      </el-table-column>
                      <Empty slot="empty" />
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </el-form-item>
              <el-form-item style="text-align: center;">
                <el-button @click="$router.back()">取消</el-button>
                <el-button type="primary" class="bgc-bv" @click="saveData">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 - 常用规则 -->
    <el-dialog title="常用规则" :visible.sync="tableData_10_ladingData.dialogVisible" width="50rem" top="1.875rem"
      @close="tableData_10_ladingDataClose">
      <el-form ref="tableData_10_ladingData" label-width="8.75rem" label-position="right" class="form"
        :rules="tableData_10_ladingDataRules" :model="tableData_10_ladingData">
        <el-form-item label="证书编号规则名称:" prop="certRoleName">
          <el-input v-model="tableData_10_ladingData.certRoleName" size="small" placeholder="请输入证书编号规则名称"></el-input>
        </el-form-item>
        <el-form-item label="备案号:" prop="recordNum">
          <el-input v-model="tableData_10_ladingData.recordNum" size="small" placeholder="备案号为五位数字、字母组合"></el-input>
        </el-form-item>
        <el-form-item label="行政区划" prop="areaId" class="form-item">
          <el-cascader ref="cascaderArr" clearable filterable v-model="tableData_10_ladingData.areaId"
            :options="areatreeList" :props="propsarea" size="small" placeholder="请选择行政区划"
            @change="getareatreeName"></el-cascader>
        </el-form-item>
        <el-form-item label="机构ID:" prop="departId">
          <el-input v-model="tableData_10_ladingData.departId" size="small" placeholder="机构ID为两位数字、字母组合"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="证书号段:" prop="codeStart">
              <el-input-number :ref="codeStart" v-model="tableData_10_ladingData.codeStart" :controls="false" :min="1"
                :max="999999999" size="small" placeholder="请输入起始值" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到" prop="codeEnd" class="codeEnd">
              <el-input-number :ref="codeEnd" v-model="tableData_10_ladingData.codeEnd" :controls="false" :min="1"
                :max="999999999" size="small" placeholder="请输入截止值" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p style="margin-bottom: 1.375rem;color: red">
        温馨提示：发证机构名称用于生成证书时显示，自定义证书编号为： 证书前缀+流水号码，流水号码为设定的流水位数,起始号码前自动补相应位数的0，请仔细核对信息，一旦生成证书发证机构信息不可修改！
      </p>
      <p style="margin-bottom: 1.375rem;color: red">
        证书编号示例：{{ certificateNumber }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tableData_10_ladingDataClose" class="bgc-bv">取 消</el-button>
        <el-button @click="tableData_10_ladingDataDetermine" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹框 - 自定义规则 -->
    <el-dialog title="自定义规则" :visible.sync="tableData_20_ladingData.dialogVisible" width="50rem" top="1.875rem"
      @close="tableData_20_ladingDataClose">
      <el-form ref="tableData_20_ladingData" label-width="8.75rem" label-position="right" class="form"
        :rules="tableData_20_ladingDataRules" :model="tableData_20_ladingData">
        <el-form-item label="证书编号规则名称:" prop="certRoleName">
          <el-input v-model="tableData_20_ladingData.certRoleName" size="small" placeholder="请输入证书编号规则名称"></el-input>
        </el-form-item>
        <el-form-item label="编号前缀:" prop="certNoPrefix">
          <el-input v-model="tableData_20_ladingData.certNoPrefix" size="small" placeholder="请输入编号前缀"></el-input>
        </el-form-item>
        <el-form-item label="流水号位数:" prop="certNoBit">
          <el-input-number style="width: 50%;" v-model="tableData_20_ladingData.certNoBit" :controls="false" :min="1"
            :max="29" size="small" placeholder="请输入流水号位数"></el-input-number>
        </el-form-item>
        <el-form-item label="编号起始号码:" prop="certNoStart">
          <el-input-number style="width: 50%;" v-model="tableData_20_ladingData.certNoStart" :controls="false" :min="1"
            :max="99999999999" size="small" placeholder="请输入编号起始号码"></el-input-number>
        </el-form-item>
      </el-form>
      <p style="margin-bottom: 1.375rem;color: red">
        温馨提示：发证机构名称用于生成证书时显示，自定义证书编号为： 证书前缀+流水号码，流水号码为设定的流水位数,起始号码前自动补相应位数的0，请仔细核对信息，一旦生成证书发证机构信息不可修改！
      </p>
      <p style="margin-bottom: 1.375rem;color: red">
        证书编号示例：{{ certificateNumber }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tableData_20_ladingDataClose" class="bgc-bv">取 消</el-button>
        <el-button @click="tableData_20_ladingDataDetermine" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";

export default {
  name: "operate_personnelDataCollectionJiLin_index",
  components: {
    Empty,
  },
  mixins: [List],
  props: {
    // 培训工种id
    occupationId: {
      type: String,
      default: ""
    },
    // 当前tabs名称
    tabsName: {
      type: String,
      default: ""
    },
    // 报名任务di
    projectId: {
      type: String,
      default: ""
    },
    // 考务任务id
    activityId: {
      type: String,
      default: ""
    },
    // 制证任务id
    taskId: {
      type: String,
      default: ""
    },
  },
  data() {
    // 备案号
    var validatorBah = (rule, value, callback) => {
      let Reg = /[^a-zA-Z0-9]/g;//正则表达式验证例子
      if (value) {
        // 位数校验
        if (value.length != 5) {
          callback(new Error("备案号为五位数字、字母组合"));
        } else {
          // 字符格式校验
          // console.log(Reg.test(value))
          if (Reg.test(value)) {	//匹配成功返回
            callback(new Error("备案号为五位数字、字母组合"));
          } else {	//匹配不成功返回错误显示
            callback();
          }
        }
      } else {
        // 必填校验
        callback(new Error("请输入备案号"));
      }
    };
    // 机构id
    var validatorJgID = (rule, value, callback) => {
      let Reg = /[^a-zA-Z0-9]/g;//正则表达式验证例子
      if (value) {
        // 位数校验
        if (value.length != 2) {
          callback(new Error("机构ID为两位数字、字母组合"));
        } else {
          // 字符格式校验
          // console.log(Reg.test(value))
          if (Reg.test(value)) {	//匹配成功返回
            callback(new Error("机构ID为两位数字、字母组合"));
          } else {	//匹配不成功返回错误显示
            callback();
          }
        }
      } else {
        // 必填校验
        callback(new Error("请输入机构ID"));
      }
    };
    return {
      // 上个页面传过来的数据
      lastPageData: {
        authorityId: "", // 发证机构id
      },
      // 页面数据
      baseform: {
        canDeletedInfo: true, // 新增&编辑 是否能修改基础数据
        authorityName: '',// 发证机构名称
        authorityNameEn: '',// 发证机构名称(英文)
        concatMan: '',// 联系人
        concatMobile: "",// 联系人电话
        certType: '10',// 证书规则
      },
      // 页面数据 - 校验
      baseformRules: {
        authorityName: [
          { required: true, message: "请输入发证机构名称", trigger: "blur" },
        ],
        authorityNameEn: [
          { required: true, message: "请输入发证机构名称(英文)", trigger: "blur" },
        ],
      },
      // 行政区划 - 下拉数据
      areatreeList: [],
      // 行政区划 - 规定字段
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
      },
      // 证书规则 - 下拉数据
      certTypeList: [],
      // 证书规则-10 常用规则 - 列表
      tableData_10: [],
      // 证书规则-10 常用规则 - 弹框数据
      tableData_10_ladingData: {
        dialogVisible: false, // 弹框显示状态
        certType: '10',// 证书规则 - 常用规则
        certRoleName: "", // 证书编号规则名称
        recordNum: '', // 备案号
        areaId: '', // 行政区划id
        areaName: '', // 行政区划名称
        departId: '', // 机构ID
        codeStart: undefined, // 号段起始值
        codeEnd: undefined, // 号段结束值
        certRoleUsable: true, // 状态
        currentCertificateNo: "",//展示证书编号
      },
      // 证书规则-10 常用规则 - 弹框数据 - 校验
      tableData_10_ladingDataRules: {
        certRoleName: [
          { required: true, message: "请输入证书编号规则名称", trigger: "blur" },
        ],
        recordNum: [
          { required: true, validator: validatorBah, trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        departId: [
          { required: true, validator: validatorJgID, trigger: "blur" },
        ],
        codeStart: [
          { required: true, message: "请输入号段起始值", trigger: "blur" },
        ],
        codeEnd: [
          { required: true, message: "请输入号段结束值", trigger: "blur" },
        ],
      },
      // 证书规则-20 自定义规则 - 列表
      tableData_20: [],
      // 证书规则-20 自定义规则 - 弹框数据
      tableData_20_ladingData: {
        dialogVisible: false, // 弹框显示状态
        certType: '20',// 证书规则 - 自定义规则
        certRoleName: "", // 证书编号规则名称
        certNoPrefix: '', // 证书编号前缀
        certNoBit: '', // 证书位数流水号
        certNoStart: '', // 证书编号起始号码
        certRoleUsable: true, // 状态
        currentCertificateNo: "",//展示证书编号
      },
      // 证书规则-20 自定义规则 - 弹框数据 - 校验
      tableData_20_ladingDataRules: {
        certRoleName: [
          { required: true, message: "请输入证书编号规则名称", trigger: "blur" },
        ],
        certNoPrefix: [
          { required: true, message: "请输入证书编号前缀", trigger: "blur" },
        ],
        certNoBit: [
          { required: true, message: "请输入证书编号位数", trigger: "blur" },
        ],
        certNoStart: [
          { required: true, message: "请输入证书编号起始号码", trigger: "blur" },
        ],
      }
    };
  },
  mounted() {

  },
  computed: {
    // 证书编号示例
    // eslint-disable-next-line vue/return-in-computed-property
    certificateNumber() {
      if (this.baseform.certType == '10') {
        if (this.tableData_10_ladingData.recordNum && this.tableData_10_ladingData.areaId && this.tableData_10_ladingData.departId) {
          return this.tableData_10_ladingData.recordNum + '' + this.tableData_10_ladingData.areaId + '' + this.tableData_10_ladingData.departId + '' + (this.tableData_10_ladingData.codeStart ? String(this.tableData_10_ladingData.codeStart).padStart(9, '0') : '000000001')
        }
        return '请填写规则'
      } else if (this.baseform.certType == '20') {
        if (this.tableData_20_ladingData.certNoPrefix && this.tableData_20_ladingData.certNoBit && this.tableData_20_ladingData.certNoStart) {
          if (this.tableData_20_ladingData.certNoBit >= String(this.tableData_20_ladingData.certNoStart).length) {
            return this.tableData_20_ladingData.certNoPrefix + '' + String(this.tableData_20_ladingData.certNoStart).padStart(this.tableData_20_ladingData.certNoBit, '0')
          } else {
            return '编号起始号码位数不能大于流水号位数'
          }
        }
        return '请填写规则'
      }
    }
  },
  watch: {

  },
  created() {
    this.lastPageData.authorityId = this.$route.query?.authorityId ?? "";
    if (this.lastPageData.authorityId) {
      this.getInfoData();
    }
    this.dataDictionary()
    this.getareatree();
  },
  methods: {
    // 获取 - 数据字典
    dataDictionary() {
      // 证书规则
      const certTypeList = this.$setDictionary(
        "CMS_CERT_TYPE",
        "list"
      );
      const list1 = [];
      for (const key in certTypeList) {
        list1.push({
          value: key,
          label: certTypeList[key],
        });
      }
      this.certTypeList = list1;
    },
    // 行政区划 - 下拉数据
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        ret.data.forEach((e, i) => {
          e.children = undefined
        })
        this.areatreeList = ret.data;
      });
    },
    // 获取详情
    getInfoData() {
      this.$post('/biz/cms/authority/getInfo', {
        authorityId: this.lastPageData.authorityId
      }, 3000, true, 6).then(res => {
        this.baseform.canDeletedInfo = res.data.canDeletedInfo;
        this.baseform.authorityName = res.data.authorityName;
        this.baseform.authorityNameEn = res.data.authorityNameEn;
        this.baseform.concatMan = res.data.concatMan;
        this.baseform.concatMobile = res.data.concatMobile;
        this.tableData_10 = res.data.cert10List;
        this.tableData_20 = res.data.cert20List;
      }
      );
    },
    // 证书规则-10 常用规则 - 弹框打开
    tableData_10_ladingDataOpen(row, index) {
      if (row) { // 编辑的时候有index
        this.tableData_10_ladingData = { listIndex: index, ...this.tableData_10_ladingData, ...row };
      }
      this.tableData_10_ladingData.dialogVisible = true;
    },
    // 证书规则-10 常用规则 - 弹框关闭
    tableData_10_ladingDataClose() {
      this.tableData_10_ladingData = this.$options.data().tableData_10_ladingData;
    },
    // 证书规则-10 常用规则 - 获取行政区划的名称，用来展示
    getareatreeName(val) {
      const checkedNode = this.$refs["cascaderArr"].getCheckedNodes()
      // console.log( checkedNode[0].data.label ) ;  //获取当前点击节点的label值
      // console.log( checkedNode[0].pathLabels) ;  //获取由label组成的数
      this.tableData_10_ladingData.areaName = checkedNode[0].data.label;
    },
    // 证书规则-10 常用规则 - 弹框确定
    tableData_10_ladingDataDetermine() {
      let _this = this;
      _this.$refs['tableData_10_ladingData'].validate((valid) => {
        if (valid) {
          if ((_this.tableData_10_ladingData.codeStart && !_this.tableData_10_ladingData.codeEnd) || (!_this.tableData_10_ladingData.codeStart && _this.tableData_10_ladingData.codeEnd)) {
            _this.$message.error("请补全证书号段");
            return false;
          }
          if (_this.tableData_10_ladingData.codeStart && _this.tableData_10_ladingData.codeEnd && _this.tableData_10_ladingData.codeStart > _this.tableData_10_ladingData.codeEnd) {
            _this.$message.error("证书号段起始值需小于等于截止值");
            return false;
          }
          _this.tableData_10_ladingData.currentCertificateNo = _this.certificateNumber;
          // 有index是编辑才有的
          if (typeof _this.tableData_10_ladingData.listIndex == 'number') {
            _this.$set(_this.tableData_10, _this.tableData_10_ladingData.listIndex, _this.tableData_10_ladingData);
          } else {
            _this.tableData_10.push(_this.tableData_10_ladingData);
          }
          this.$forceUpdate();
          _this.tableData_10_ladingDataClose();
        }
      });
    },
    // 证书规则-10 常用规则 - 列表 - 删除
    tableData_10_delete(row, index) {
      this.tableData_10.splice(index, 1);
    },
    // 证书规则-20 自定义规则 - 弹框打开
    tableData_20_ladingDataOpen(row, index) {
      if (row) {
        this.tableData_20_ladingData = { ...this.tableData_20_ladingData, ...row, listIndex: index };
      }
      this.tableData_20_ladingData.dialogVisible = true;
    },
    // 证书规则-20 自定义规则 - 弹框关闭
    tableData_20_ladingDataClose() {
      this.tableData_20_ladingData = this.$options.data().tableData_20_ladingData;
    },
    // 证书规则-20 自定义规则 - 弹框确定
    tableData_20_ladingDataDetermine() {
      let _this = this;
      _this.$refs['tableData_20_ladingData'].validate((valid) => {
        if (valid) {
          if (_this.tableData_20_ladingData.certNoPrefix.length + _this.tableData_20_ladingData.certNoBit > 30) {
            _this.$message.error("证书编号前缀加流水号位数需小于30位");
            return false;
          }
          if (_this.tableData_20_ladingData.certNoBit < String(_this.tableData_20_ladingData.certNoStart).length) {
            _this.$message.error('编号起始号码位数不能大于流水号位数');
            return false
          }
          _this.tableData_20_ladingData.currentCertificateNo = _this.certificateNumber;
          // 有index是编辑才有的
          if (typeof _this.tableData_20_ladingData.listIndex == 'number') {
            this.$set(_this.tableData_20, _this.tableData_20_ladingData.listIndex, _this.tableData_20_ladingData);
          } else {
            _this.tableData_20.push(_this.tableData_20_ladingData);
          }
          _this.tableData_20_ladingDataClose();
        }
      });
    },
    // 证书规则-20 自定义规则 - 列表 - 删除
    tableData_20_delete(row, index) {
      this.tableData_20.splice(index, 1);
    },
    // 新增&编辑 - 确定
    saveData() {
      this.$refs['baseform'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.baseform,
            cert10List: this.tableData_10,
            cert20List: this.tableData_20,
          };
          let url = "";
          if (this.lastPageData.authorityId) {
            params.authorityId = this.lastPageData.authorityId;
            url = "/biz/cms/authority/modify";
          } else {
            url = "/biz/cms/authority/add";
          }
          this.$post(url, params, 3000, true, 6).then(
            (res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.$router.back();
              }
            }
          );
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.codeEnd {
  /deep/ .el-form-item__label {
    text-align: center !important;
  }
}</style>
